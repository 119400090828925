import entityLoader from "components/Entities/EntityLoader";
import { getId as getCreatorId } from "components/EntityWrappers/creatorWrapper";

import paginationActions from "actions/pagination";
import * as sortConstants from "constants/sort";
import loadCreditsList from "sagas/pagination/lists/loadCreditsList";
import { selectSpecificCredit } from "selectors/creator";
import {
  selectListIds,
  selectListLoaded,
  selectListLoading,
} from "selectors/pagination";
import isEqual from "utils/isEqual";

export const getId = (props, suffix = "credits") =>
  `creator/${getCreatorId(props)}/${suffix}`;

const groupsDefault = [
  { field: "role", size: 50 },
  { field: "podcast_id", size: 99999 },
];

export const getInitParams = ({
  creator_id,
  groups = groupsDefault,
  filters = {},
  suffix,
  pageSize = 25,
  sort,
}) => ({
  key: getId({ creator_id }, suffix),
  sort: sort || sortConstants.SORT_ORDER_RECENT_WEIGHTED,
  list_type: "credits_filtered",
  loadListAction: loadCreditsList,
  entity_type: "creator_credit",
  pageSize,
  staticFilters: {
    creator_id: { value: creator_id },
  },
  filters,
  groups,
});

export default ({
  groups,
  suffix = "credits",
  pageSize = 25,
  ...options
} = {}) =>
  entityLoader({
    itemName: "credits",
    selector: (state, props) =>
      selectListIds(state, getId(props, suffix)).map((id) =>
        selectSpecificCredit(state, id)
      ),
    loaders: {
      initList: paginationActions.initList,
      replaceList: paginationActions.replaceList,
    },
    loadingSelector: (state, props) =>
      props.creator && selectListLoading(state, { key: getId(props, suffix) }),
    loadedSelector: (state, props) =>
      !props.creator || selectListLoaded(state, { key: getId(props, suffix) }),
    testFunc: (props) => !props.loaded && !props.loading,
    execOnMount: (props) => {
      if (props.creator && props.creator.get("id") !== "new") {
        props.initList(
          getInitParams({
            creator_id: props.creator && props.creator.get("id"),
            groups,
            filters: props.isModerating ? { confidence: { value: "all" } } : {},
            suffix,
            pageSize,
          })
        );
      }
    },
    compareFunc: (props, newProps) =>
      !props.creator ||
      !newProps.creator ||
      props.creator.get("id") !== newProps.creator.get("id") ||
      (props.creator &&
        !isEqual(
          props.creator.get("credits"),
          newProps.creator.get("credits")
        )),
    ...options,
  });
