import branch from "recompose/branch";
import renderComponent from "recompose/renderComponent";

import LoadingOverlay from "components/Common/LoadingOverlay";

const Loading =
  (key = "withLoadingLoadingOverlay", noOverlay, noPadding, styles) =>
  () =>
    (
      <LoadingOverlay
        noOverlay={noOverlay}
        noPadding={noPadding}
        styles={styles}
        key={key}
      />
    );

/*
 * Renders a loading component instead of the passed component if testFunc returns true
 * */
const withLoading = (
  testFunc,
  key = "withLoadingLoadingOverlay",
  noOverlay = false,
  noPadding = false,
  loadingStyles = null
) =>
  branch(
    (props) => testFunc(props),
    renderComponent(Loading(key, noOverlay, noPadding, loadingStyles)),
    (a) => a
  );

export default withLoading;
