import branch from "recompose/branch";
import renderComponent from "recompose/renderComponent";

import Error404 from "pages/Error404/Async";

/*
 * Renders a 404 component instead of the passed component if testFunc returns true
 * */
const test404 = (testFunc, ErrorComp = null) =>
  branch(testFunc, renderComponent(ErrorComp || Error404), (a) => a);

export default test404;
