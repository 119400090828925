import { episodeCreditSchema } from "api/schema/creator_podcast";
import { fromJS, List, Map } from "immutable";
import { normalize } from "normalizr";
import { call, put } from "redux-saga/effects";

import { getModeratorModeCookie } from "components/UseIsModerator/UseIsModeratorProvider";

import * as paginationConstants from "constants/pagination";
import { getCreditEpisodesKey, getValueForGroup } from "utils/entity/creator";

function* createEpisodeLists(
  data,
  passedGroupPath,
  groups,
  pagination,
  result = null
) {
  let groupPath = passedGroupPath;

  if (groupPath.size < groups.size) {
    yield put({
      type: paginationConstants.PAGINATION_UPDATE_LIST,
      key: pagination.get("key"),
      additional: result.getIn(["response", "additional"]),
    });
    const thisGroup = groups.get(groupPath.size);

    groupPath = groupPath.push(data.delete(`by_${thisGroup.get("field")}`));

    const values = data.get(`by_${thisGroup.get("field")}`);
    for (let i = 0; i < values.size; i++) {
      yield call(
        createEpisodeLists,
        values.get(i),
        groupPath,
        groups,
        pagination,
        result
      );
    }
    return;
  }

  groupPath = groupPath.push(data).shift();

  const creator_id = pagination.getIn(["staticFilters", "creator_id", "value"]);

  if (!creator_id) {
    return;
  }

  const key = getCreditEpisodesKey(creator_id, groupPath, groups);

  const filters = groupPath.reduce(
    (agg, path, i) =>
      agg.set(
        groups.getIn([i, "field"]),
        fromJS({ value: getValueForGroup(groups.get(i), path) })
      ),
    Map()
  );

  const isModerating = getModeratorModeCookie();

  // initialise the list and fire off a success with just our current values in there
  yield put({
    type: paginationConstants.PAGINATION_INIT_LIST_CREATE,
    key,
    filters,
    list_type: "credits_filtered",
    entity_type: "creator_credit",
    staticFilters: {
      creator_id: { value: creator_id },
      confidence: isModerating ? { value: "all" } : {},
    },
    sort: pagination.get("sort"),
    pageSize: 100,
    additional: result.getIn(["response", "additional"]),
  });

  const normalized = normalize(data.get("grouped_hits").toJS(), [
    episodeCreditSchema,
  ]);

  yield put({
    type: paginationConstants.PAGINATION_NEXT_PAGE_SUCCESS,
    key,
    sort: pagination.get("sort"),
    response: {
      ...normalized,
      meta: {
        total: data.get("count"),
        has_more: data.get("grouped_hits").size < data.get("count"),
      },
      additional: result.getIn(["response", "additional"]),
    },
  });
}

// create new lists for each group of episodes so we can easily load more when needed
export default function* onCreditListLoad(passedData) {
  const data = fromJS(passedData);

  if (!data.hasIn(["pagination", "groups"])) {
    return;
  }

  const creditsList = data.getIn(["response", "entities", "credits"]).toList();

  // for each leaf node in the response, create a new list
  for (let i = 0; i < creditsList.size; i++) {
    yield call(
      createEpisodeLists,
      creditsList.get(i),
      List(),
      data.getIn(["pagination", "groups"]),
      data.get("pagination"),
      data
    );
  }
}
