import { Component } from "react";

/*
 * Executes a function when the component mounts if the testFunc returns true
 * */
const execOnMount =
  (execFunc, mountTestFunc, compareProps = () => false) =>
  (ComposedComponent) =>
    class Wrapper extends Component {
      componentDidMount() {
        if (mountTestFunc(this.props)) {
          // console.log('executing on mount', ComposedComponent.displayName || ComposedComponent.name);
          execFunc(this.props);
        }
      }

      UNSAFE_componentWillReceiveProps(newProps) {
        if (compareProps(this.props, newProps)) {
          /* console.log(
        'executing based on new props',
        ComposedComponent.displayName || ComposedComponent.name
      ); */
          execFunc(newProps);
        }
      }

      render() {
        return <ComposedComponent {...this.props} />;
      }
    };

export default execOnMount;
