import { memo } from "react";
import branch from "recompose/branch";
import { compose } from "redux";

import { selectAuthUser } from "selectors/auth";
import execOnMount from "utils/execOnMount";
import test404 from "utils/test404";
import withLoading from "utils/withLoading";

import useActionCreators from "hooks/useActionCreators";
import useIsModerator from "hooks/useIsModerator";
import useReduxState from "hooks/useReduxState";

export default (options) => (PassedComponent) => {
  const EntityLoader = (props) => <PassedComponent {...props} />;

  const testFunc =
    options.testFunc ||
    ((props) =>
      !props[options.itemName || "item"] ||
      (options.fullRecord &&
        !props[options.itemName || "item"].get("full_record")));

  const execFunc = (props) => options.execOnMount(props);

  const EntityLoaderWithExec = compose(
    execOnMount(execFunc, testFunc, options.compareFunc),
    branch(
      () => options.with404,
      test404((props) => props.failed)
    ),
    withLoading(
      testFunc,
      "entityLoader",
      options.noLoadingOverlay,
      options.noLoadingPadding,
      options.loadingStyles
    )
  )(EntityLoader);

  const EntityLoaderRedux = (props) => {
    const actions = useActionCreators({ ...options.loaders });

    const { isModerator } = useIsModerator();
    const resultsState = useReduxState(
      (state) => {
        let newProps = {};
        const results = options.selector(state, {
          ...props,
          isModerating: isModerator,
        });

        if (options.spreadResults) {
          newProps = { ...results };
        } else {
          newProps[options.itemName || "item"] = results;
        }
        return newProps;
      },
      [props, isModerator]
    );

    const loadingState = useReduxState(
      (state) => ({
        loading: options.loadingSelector(state, props),
        loaded: options.loadedSelector
          ? options.loadedSelector(state, props)
          : true,
        failed: options.failedSelector
          ? options.failedSelector(state, props)
          : false,
      }),
      [props]
    );

    const user = useReduxState((state) => selectAuthUser(state), []);

    return (
      <EntityLoaderWithExec
        {...props}
        {...actions}
        user={user}
        isModerating={isModerator}
        {...loadingState}
        {...resultsState}
      />
    );
  };

  return memo(EntityLoaderRedux);
};
